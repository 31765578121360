.my-row-1 {
  background-color: #e0e0e0;
}
.my-row-1:hover > * {
  background-color: #cecdcd !important;
}

.my-row-2 {
  background-color: #bebebe;
}
.my-row-2:hover > * {
  background-color: #a8a8a8 !important;
}

.my-row-3 {
  background-color: #9d9d9d;
}
.my-row-3:hover > * {
  background-color: #868686 !important;
}

.drag-visible svg {
  color: #0a2844;
}
