/* .ant-menu-dark.ant-menu-horizontal.logout > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal.logout > .ant-menu-submenu {
  padding: 0 20px;
}

@media (min-width: 1024px) {
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    padding: 0 7px;
  }
}

@media (min-width: 1680px) {
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    padding: 0 20px;
  }
} */



.ant-back-top.my-back-top {
  position: fixed;
  bottom: 100px;
  right: 10px;
}

.ant-layout-footer.my-footer {
  background-color: #001529;
  color: hsla(0, 0%, 100%, 0.65);
}
