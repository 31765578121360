@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  outline: none !important;
}

body {
  min-width: 100%;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px !important;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg {
  vertical-align: baseline;
}

button.hero-btn {
  width: 140px;
  height: 40px;
  display: flex;
  font-size: 18px;
}

button.pricing-btn {
  width: 200px;
}

@media (min-width: 1400px) {
  button.hero-btn {
    width: 160px;
    height: 50px;
    display: flex;
    font-size: 24px;
  }

  button.pricing-btn {
    width: 400px;
  }
}

/* medicinesNav */

.medicineNavMenu > div {
  height: calc(100vh - 80px);
}

.medicineNavSegment {
  width: 500px;
}

.navSegmentContent {
  height: 300px;
}

.ant-tree-title {
  font-size: 18px;
}

.ant-tree .ant-tree-treenode {
  padding-bottom: 1px;
}

.ant-table {
  font-size: 14px;
}

/* ipad portrait */
@media (min-width: 768px) and (orientation: portrait) {
  .medicineNavMenu > div {
    height: 850px;
  }

  .medicineNavSegment {
    width: 500px;
  }

  .navSegmentContent {
    height: 840px;
  }
}

/* ipad landscape */
@media (min-width: 1024px) {
  .medicineNavMenu > div {
    height: 630px;
  }

  .medicineNavSegment {
    width: 580px;
  }

  .navSegmentContent {
    height: 600px;
  }

  .ant-tree-title {
    font-size: 18px;
  }

  .ant-tree .ant-tree-treenode {
    padding-bottom: 3px;
  }

  .ant-table {
    font-size: 16px;
  }
}

/* (1366*768) */
@media (min-width: 1366px) {
  .medicineNavMenu > div {
    height: 620px;
  }

  .medicineNavSegment {
    height: calc(100% - 35px);
  }

  .navSegmentContent {
    height: 600px;
  }

  .ant-tree-title {
    font-size: 18px;
  }
}

/* iPad Pro landscape (1366*1024) */
@media (min-width: 1366px) and (min-height: 1000px) {
  .medicineNavMenu > div {
    height: 900px;
  }

  .medicineNavSegment {
    width: 500px;
  }

  .navSegmentContent {
    height: calc(100% - 35px);
  }

  .ant-tree .ant-tree-treenode {
    height: 27px;
  }

  .ant-tree-title {
    font-size: 18px;
  }
}

/* iPad Pro portrait (1024*1366) */
@media (min-width: 1024px) and (orientation: portrait) {
  .medicineNavMenu > div {
    height: 900px;
  }

  .medicineNavSegment {
    width: 480px;
  }

  .navSegmentContent {
    height: calc(100% - 35px);
  }

  .ant-tree-treenode {
    height: 28px;
  }

  .ant-tree-title {
    font-size: 18px;
  }
}

/* (1440*900) */
@media (min-width: 1440px) and (min-height: 800px) {
  .medicineNavMenu > div {
    height: 670px;
  }

  .medicineNavSegment {
    width: auto;
  }

  .navSegmentContent {
    height: calc(100% - 35px);
  }

  .ant-tree-treenode {
    padding-bottom: 0px;
    height: 22px;
  }

  .ant-tree-title {
    font-size: 14px;
  }
}

@media (min-width: 1680px) {
  .medicineNavMenu > div {
    height: 910px;
  }

  .medicineNavSegment {
    width: auto;
  }

  .navSegmentContent {
    height: calc(100% - 35px);
  }

  .ant-tree-treenode {
    padding-bottom: 1px;
    height: 24px;
  }

  .ant-tree-title {
    font-size: 20px;
  }

  .ant-table {
    font-size: 19px;
  }
}

@media (min-width: 1920px) {
  .medicineNavMenu > div {
    height: calc(100vh);
  }

  .medicineNavSegment {
    width: auto;
  }

  .navSegmentContent {
    height: calc(100% - 35px);
  }

  .ant-tree .ant-tree-treenode {
    padding-bottom: 3px;
    height: 28px;
  }

  .ant-tree-title {
    font-size: 20px;
  }

  .ant-table {
    font-size: 19px;
  }
}

/* history-search */
.history-search-bar > div {
  /* width: 600px; */
}

.history-search-bar.search-bar-customInput input {
  height: 53px;
}

.history-search-bar .ant-input-search-large .ant-input-search-button {
  height: 53px;
  font-size: 24px;
}

.ant-select .ant-input-lg {
  font-size: 24px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 0px;
}

.ant-tag.history-tag {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-tag.history-tag .ant-tag-close-icon {
  font-size: 20px;
}

.active-autoComplete-row {
  background-color: rgb(241 245 249);
}