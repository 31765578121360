.ant-table-row:hover {
  z-index: 50;
  overflow:visible
}

.ant-table-row.my-row-1 {
  background-color: #e0e0e0;
}
.ant-table-row.my-row-1:hover > * {
  background-color: #cecdcd !important;
}
.ant-table-row.my-row-1.ant-table-row-selected:hover > * {
  background-color: rgb(220, 244, 255) !important;
}

.ant-table-row.my-row-2 {
  background-color: #bebebe;
}
.ant-table-row.my-row-2:hover > * {
  background-color: #a8a8a8 !important;
}
.ant-table-row.my-row-2.ant-table-row-selected:hover > * {
  background-color: rgb(220, 244, 255) !important;
}

.ant-table-row.my-row-3 {
  background-color: #9d9d9d;
}
.ant-table-row.my-row-3:hover > * {
  background-color: #868686 !important;
}
.ant-table-row.my-row-3.ant-table-row-selected:hover > * {
  background-color: rgb(220, 244, 255) !important;
}
