:root {
  --pink-300: rgb(249 168 212);
  --pink-400: rgb(244 114 182);
  --green-300: rgb(134 239 172);
  --green-400: rgb(74 222 128);
}

.ICD-search button, .treatment-search button {
  background-color: var(--pink-400);
  border: var(--pink-400);
}

.ICD-search button:hover, .treatment-search button:hover {
  background-color: var(--pink-300);
  background-color: var(--pink-300);
}

.ICD-search input:hover,.treatment-search input:hover {
  border-color: var(--pink-400) !important;
}

.ICD-search input:hover, .treatment-search input:hover {
  border-color: var(--pink-400) !important;
}

.ICD-search input:focus, .treatment-search input:focus {
  border-color: var(--pink-400) !important;
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 1px var(--pink-300) !important;
  box-shadow: 0 0 0 1px var(--pink-300) !important;
}

.ICD-search-mostUsed button,.treatment-search-mostUsed button {
  background-color: var(--green-400);
  border: var(--green-400);
}

.ICD-search-mostUsed button:hover,.treatment-search-mostUsed button:hover {
  background-color: var(--green-300);
  background-color: var(--green-300);
}

.ICD-search-mostUsed input:hover,.treatment-search-mostUsed input:hover {
  border-color: var(--green-400) !important;
}

.ICD-search-mostUsed input:hover,.treatment-search-mostUsed input:hover {
  border-color: var(--green-400) !important;
}

.ICD-search-mostUsed input:focus ,.treatment-search-mostUsed input:focus{
  border-color: var(--green-400) !important;
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 1px var(--green-300) !important;
  box-shadow: 0 0 0 1px var(--green-300) !important;
}