.ant-tabs-tab {
  width: 150px;
  display: flex;
  justify-content: space-between;
}

.ant-tabs-tab > div:first-child {
  width: 100%;
  text-align: center;
}
